import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { fontLoader } from "@/utils/font_loader/font_loader";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    console.log("mounted>>>>");
  },
}).$mount("#app");

fontLoader();
