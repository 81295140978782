import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
const TTGuideComponent = () => import("@/views/guides/tt_guides/TTGuides.vue");
const TTGuideEarn = () => import("@/views/guides/tt_guides/TTGuideEarn.vue");
const ThemeGuideComponent = () =>
  import("@/views/guides/theme_guides/ThemeGuides.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/tt/guides",
    name: "ttGuide",
    component: TTGuideComponent,
  },
  {
    path: "/theme/guides",
    name: "themeGuide",
    component: ThemeGuideComponent,
  },{
    path: "/tt/guides/one",
    name: "TTGuideEarn",
    component: TTGuideEarn,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
