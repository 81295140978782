/*
 * @Author: jhonsem
 * @Date: 2021-01-28 14:53:08
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-02-24 18:05:48
 *
 * https://fonts.google.com/specimen/Noto+Sans?sidebar.open=true&selection.family=Noto+Sans:wght@700
 * https://fonts.google.com/specimen/Varela+Round?sidebar.open=true&selection.family=Varela+Round
 * https://fonts.google.com/specimen/Poppins?sidebar.open=true&selection.family=Poppins:wght@600
 */
const WEB_FONT_URL =
  "https://cdn.jsdelivr.net/npm/webfontloader@1.6.28/webfontloader.min.js";

export const fontLoader = () => {
  window.WebFontConfig = {
    google: {
      families: ["Amiko", "Amiko:700"],
    },
  };

  // 异步添加字体:
  const wf = document.createElement("script");
  const script: any = document.scripts[0];
  wf.src = WEB_FONT_URL;
  wf.async = true;
  script.parentNode.insertBefore(wf, script);
};
